<template>
  <div class="tasks-block">
    <div
      v-for="(taskItem, index) in pokerSession.tasks"
      :key="index"
    >
      <div
        :class="`${highlightedTask === index ? 'chosen-box-card' : 'box-card'}`"
        @click="activateTask(taskItem)"
      >
        <div
          class="task-name"
        >
          <div>
            {{ taskItem.description }}
          </div>
          <div v-if="organizer || pokerSession.options.showScore">
            {{ taskItem.score }}
          </div>
        </div>
        <div
          class="delete-button"
        >
          <el-button
            v-if="organizer"
            class="delete"
            type="danger"
            icon="el-icon-close"
            plain
            @click.stop="removeTask(taskItem)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'TaskList',
  props: {
    pokerSession: {
      type: Object,
      require: false,
      default: () => null,
    },
  },
  data() {
    return {
      lastChosenId: '',
    };
  },
  computed: {
    ...mapGetters([
      'user',
    ]),
    organizer() {
      if (this.pokerSession.exists()) {
        return this.pokerSession.user.uuid === this.user.uuid;
      }

      return true;
    },
    highlightedTask() {
      return this.pokerSession.activeTask ? this.pokerSession.tasks.findIndex(el => el.uuid === this.pokerSession.activeTask.uuid) : -1;
    },
  },
  methods: {
    async activateTask(task) {
      if (this.organizer && this.pokerSession.exists()) {
        this.resetVotes();
        this.pokerSession.activeTask = task;
        this.pokerSession.update();
      }
    },
    removeTask(task) {
      const index = this.pokerSession.tasks.indexOf(task);
      const last = this.pokerSession.tasks[this.pokerSession.tasks.length - 1];

      if (this.pokerSession.exists() && this.pokerSession.activeTask !== task) {
        this.pokerSession.update();
      }
      if (this.pokerSession.exists() && this.pokerSession.activeTask === task && this.pokerSession.activeTask !== last) {
        this.activateTask(this.pokerSession.tasks[index + 1]);
      }
      if (this.pokerSession.exists() && this.pokerSession.activeTask === task && this.pokerSession.activeTask === last) {
        this.activateTask(this.pokerSession.tasks[index - 1]);
      }
      this.pokerSession.tasks.splice(index, 1);
      this.$emit('remove-task');
    },
    resetVotes() {
      this.$emit('reset-votes');
    },
  },
};

</script>

<style scoped>
  .tasks-block {
    display      : grid;
    max-height: 378px;
    overflow: auto;
    background: #ffffff;
  }

  .box-card {
    display: grid;
    height: 40px;
    border-bottom: 1px solid #ebeef5;
    align-content: space-between;
    padding: 10px;
    cursor: pointer;
  }
  .chosen-box-card {
    display: grid;
    height: 40px;
    border-bottom: 1px solid #ebeef5;
    align-content: space-between;
    padding: 10px;
    cursor: pointer;
    background: #ecf5ff;
  }
  .task-name {
    font-size : 14px;
    display: flex;
     justify-items: center;
    justify-content: space-between;
  }
  .estimated-box-card {
    background : lightgreen;
  }
  .delete-button {
    height: 11px;
    display: grid;
    justify-items: end;
  }
  .delete {
    font-size     : 8px;
    padding       : 1px;
  }
  .story-point {
    font-size     : 14px;
    float         : left;
    margin-bottom : 8px;
    font-weight   : 600;
  }
</style>
